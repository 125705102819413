import { reactive } from "vue";
import { io } from "socket.io-client";
import config from "./config";

let initLocalStorage = {token:false};
try{
    initLocalStorage = JSON.parse(localStorage.getItem(config.API_STORAGE));
    if(!initLocalStorage.hasOwnProperty('token')){
        initLocalStorage = {token: false};
    }
} catch(err){
    console.error('issues parsing local storage');
    initLocalStorage = {token: false}
}
const state = reactive({
    connected: false,
    lastPing: null,
    airports: ["testroom"],
    authToken: initLocalStorage.token
});

const URL = config.SOCKET_URL;


function initSocket(url,token = state.authToken){
    try{
        return io(url,{
            //path: '/socket'
            auth: {
                token: token
            }
        }).connect();
    } catch(err){
        console.error('error initializing socket');
    }
}

let socket = initSocket(URL,state.authToken);


function restartSocket(token = state.authToken){
    console.log('restarting... state:',state);
    socket.disconnect();
    injectToken(token);
    socket.connect();
}


function injectToken(token = state.authToken){
    console.log('injectToken');
    socket.io.opts.auth = {
        token: token
    }
}

socket.on("connect",()=>{
    state.connected = true;
    state.lastPing = Date.now();
    if(state.airports.length && !socket.recovered){
        socket.emit("subscribe",state.airports);
    }
    socket.emit("subscribe","radar");
    socket.emit("subscribe", "sigmet");
    
    console.log('Socket connected');
});

socket.on("disconnect",(reason,details)=>{
    state.connected = false;
    console.log("Socket disconnected due:", reason,'details:', details);
});

socket.io.on("reconnect",(attempt)=>{
    state.connected = true;
    state.lastPing = Date.now();
    console.log('reconnected: '+attempt);
});
socket.io.on("reconnect_attempt",(attempt)=>{
    injectToken()
    console.log('reconnecting...');
});

socket.on("connect_error",(err)=>{
    injectToken()
    console.log('Socket connection error',err);
});

socket.on("version",(resp)=>{
    if(typeof(resp)==='number'){
        if(resp != config.version){
            console.log(false);
            location.reload();
        }
    }
})

socket.io.on("ping",()=>{
    state.lastPing = Date.now();
    //console.log('ping',state.lastPing);
});

socket.onAny((event)=>{
    if(socket.listeners(event).length===0){
        console.log('missing handler for event',event);
    }
});


function subscribeTo(airports = []){
    airports.forEach(el=>{
        if(state.airports.indexOf(el) === -1){
            state.airports.push(el);
            socket.emit("subscribe",el);
            console.log('subscribed to:',el)
        }

    })

}
function unsubscribeTo(airports = []){
    airports.forEach((el,i)=>{
        if(state.airports.indexOf(el) != -1){
            socket.emit("unsubscribe",el);
            state.airports.slice(i,1);
        }

    })
}

export {
    socket,
    state,
    subscribeTo,
    unsubscribeTo,
    restartSocket
}