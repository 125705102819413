import 'material-icons';
import 'materialize-css/dist/css/materialize.min.css';
import 'leaflet/dist/leaflet.css';
import '@/assets/main.css';
import 'materialize-css/dist/js/materialize.min.js';
import router from '@/router.js';

import { createApp } from 'vue'
import App from './App.vue'


M.AutoInit();

const app = createApp(App)
app.use(router);
app.mount('#app');



setTimeout(()=>{ //reload page every 24h to catch recurring issues
    location.reload();
},1000*60*60*24);