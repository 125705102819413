import { createRouter, createWebHistory } from 'vue-router';

import Dashboard from '@/components/Dashboard.vue';
import Login from '@/components/Login.vue';
import Map from '@/components/Map.vue';

const routes = [
    {path: '/', component: Dashboard},
    {path: '/dash', redirect: '/'},
    {path: '/login', component: Login},
    {path: '/map', component: Map}
];

const router = createRouter({
    history: createWebHistory(),
    routes
});
export default router