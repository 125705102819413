<template>
    <div class="card">
        <div id="map" class="card"></div>
    </div>
</template>

<script setup>
import config from '@/config';
import { UTCtime } from '@/helpers';
//import { socket } from '@/socket';
import { computed, inject, onMounted, ref, watch } from 'vue';
import L from 'leaflet';

const auth = inject('auth');

const lastUpdate = ref(UTCtime(new Date()));
const map = ref(null);

const stats = ref({
    zoom: 0,
    pos: []
})

const props = defineProps({
  sigmets: Object,
});

const polygons = ref([]);
onMounted(()=>{
    map.value = L.map('map').setView([65.1,21.4],4);
    L.tileLayer('https://tile.openstreetmap.org/{z}/{x}/{y}.png',{
        attribution: '&copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a> Contributors'
    }).addTo(map.value);
    map.value.on('move zoom',()=>{
        stats.value = {
            zoom: map.value.getZoom(),
            pos: map.value.getCenter()
        };
        console.log(stats.value);
    })
});


watch(props,()=>{
    polygons.value = [];
    map.value.closePopup();
    props.sigmets.forEach(element => {
        let coordinates = [];
        let options = {
            color: 'red',
            fillColor:'#f03',
        }
        element.coordinates.forEach(cord=>{
            coordinates.push([cord.lat,cord.lon]);
        });
        if(element.metType=="AIRMET"){
            options.color = 'blue';
            options.fillColor = '#04f'
        }

        polygons.value.push(L.polygon([coordinates],options)
            .addTo(map.value)
            .bindPopup('<b>'
                +element.metType+' '
                +element.identifier+'</b><br/>'+
                element.raw)
            .bindTooltip(element.identifier+'<br/>'+element.altitude,{
                permanent: true
            }));
    });
});


</script>

<style scoped>
#map {
    position: relative;
    aspect-ratio: 0.7/1 auto;
    background-color: #fcf;
}
</style>

