<template>
  <div class="card">
    <div class="card-image">
        <img :src="imgPath">
        <span class="card-title">last update: {{ lastUpdate }}</span>
    </div>
  </div>
</template>

<script setup>
import config from '@/config';
import { UTCtime } from '@/helpers';
import { socket } from '@/socket';
import { computed, inject, onMounted, ref, watch } from 'vue';

const imgPath = ref("");
const auth = inject('auth');

const props = defineProps(['sigImage']);
const site = ref("nordic");
const lastUpdate = ref(UTCtime(new Date()));

onMounted(()=>{
    fetchImage(site.value);
    socket.on("sigChart",(data)=>{
        if(data = site.value){
            fetchImage(site.value);
        }
    });
})

watch(props,()=>{
    fetchImage(props.radarImage.site);
    console.log(props.radarImage);
})

function fetchImage(sigMap='nordic'){
    try{
        const url = `${config.FILE_URL}/sigchart/${sigMap}`;
        const method = 'GET';
        const headers = {
            'Authorization': 'Bearer '+auth.value.token
        }
        const options = {
            method: method,
            headers: headers

        }
        return fetch(url,options)
        .then(res=>{
            if(res.ok){
                return res.blob();
            }
            else {
                throw new Error('Server error:',res.status);
            }
        }).then(blob=>{
            imgPath.value = URL.createObjectURL(blob);
            lastUpdate.value = UTCtime(new Date());
        }).catch(err=>{
            throw err;
        });
    } catch(err){
        console.error('fetching error:', err);
        return NULL;
    }
}

</script>

<style scoped>
span.card-title {
    font-size: 12px;
}
</style>