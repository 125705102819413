<template>
    <div class="airport-wx">
    <li class="collection-item" 
    @mouseenter="mouseHover = true"
    @mouseleave="mouseHover = false">
        <span class="title">{{ airport.name }}</span>
        <b class="windshear" :class="windShear.class">{{ windShear.text }}</b>
        <span class="updated right">last update: {{ lastUpdated }}</span>
        <br/>
        <span v-if="airport.metar">
            METAR:
            <span 
                v-for="(part,index) in metar" 
                v-bind:key="index"
                :class="part.class">
                    {{ part.value }}&ThinSpace; 
            </span>
            <div v-if="metar.length < 1"
            class="progress"><div class="indeterminate"></div></div>
        </span>
        <span v-if="airport.taf">
            <br/>
            TAF:
            <span 
                v-for="(tpart,index) in taf"
                v-bind:key="index"
                :class="tpart.class">
                    {{ tpart.value }}&ThinSpace;
            </span>
        </span>
        <div v-if="mouseHover" class="secondary-content">
                <!-- <a class="waves-effect btn">
                    <i class="material-icons">drag_handle</i>
                </a> -->
                <!-- <a v-on:click="editThis()"
                    class="waves-effect btn">
                    <i class="material-icons">mode_edit</i>
                </a> -->
                <a v-on:click="deleteThis()"
                    class="waves-effect btn">
                    <i class="material-icons">delete</i>
                </a>
        </div>
    </li>
    <div class="divider"></div>
    </div>
</template>

<script setup>
import { ref, watchEffect, computed, reactive} from 'vue';
import { UTC6dig, UTCdate, UTCtime } from '@/helpers'

const props = defineProps({
    airport: {
        type: Object,
        default: false
    },
    index: {
        type: Number
    }
});
//console.log(props.airport);

const mouseHover = ref(false);

const emit = defineEmits(['deleteThis','editThis']);

const lastUpdated = computed(()=>{
    return UTCtime(new Date(props.airport.lastUpdate));
})

const metar = computed(()=> {
    const latest = ref(props.airport.metar.latest);
    let arr = [];
    let restricted = false;
    if(typeof latest.value === 'undefined'){
        arr = [{value: 'NO VALID METAR',class:''}];
        return arr;
    }
    if(latest.value.raw_parts != undefined){
        latest.value.raw_parts.forEach(el =>{
            arr.push({
                value: el,
                class: ''
            });
        })
    }
    if(latest.value.wind != undefined){
        if(latest.value.wind.restricted){
            latest.value.wind.raw_part.forEach(el => {
                arr[el].class='red-text restrictedItem';
                restricted = true;
            });
        }
    }
    if(latest.value.remark != undefined && latest.value.remark.wind != undefined){
        latest.value.remark.wind.forEach(el => {
            if(el.restricted){
                el.raw_part.forEach(i => {
                    arr[i].class='red-text restrictedItem';
                    restricted = true;
                })
            }
        });
    }
    //hasRestriction = restricted;
    return arr;
});

const windShear = computed(()=>{
    const ws = ref(props.airport.windShear);
    if(ws.value) {
        return {
            text: ' WS: '+ws.value.warning+' '+UTC6dig(ws.value.validity.from)+'/'+UTC6dig(ws.value.validity.to),
            class: ws.value.severity == "MOD" ?  'orange-text text-darken-4' :'red-text'
        }
    }
    return {
        text:'',
        class:''
    }
});


const taf = computed(()=> {
    const latest = ref(props.airport.taf.latest);
    let arr = [];
    let restricted = false;
    try{
        if(typeof latest.value === 'undefined'){
            arr = [{value: 'NO VALID TAF',class:''}];
            return arr;
        }
        if(latest.value.raw_parts != undefined){
            latest.value.raw_parts.forEach(el =>{
                arr.push({
                    value: el,
                    class: ''
                });
            })
        }
        if(latest.value.wind != undefined){
            if(latest.value.wind.restricted){
                latest.value.wind.raw_part.forEach(el => {
                    arr[el].class='red-text restrictedItem';
                    restricted = true;
                });
            }
        }
        if(latest.value.remark != undefined && latest.value.remark.wind != undefined){
            latest.value.remark.wind.forEach(el => {
                if(el.restricted){
                    el.raw_part.forEach(i => {
                        arr[i].class='red-text restrictedItem';
                        restricted = true;
                    })
                }
            });
        }
        //hasRestriction = restricted;
        return arr;
    } catch (err){
        console.warn('error parsing taf:',err);
    }
});

watchEffect(async ()=> {
})


function deleteThis(){
    emit('deleteThis',props.index);
    console.log('delete',props.index);
}

function editThis(){
    console.log('edit');
    console.log(props.airport)
}

</script>

<style scoped>
.restrictedItem {
    font-weight: bold;
}
.title {
    font-size: 0.8rem;
    font-weight: bold;
}
.airport-wx {
    font-size: 12px;
}
.collection-item {
    line-height: 1rem;
    padding: 0px 20px;
}
</style>