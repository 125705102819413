<template>
    <footer class="page-footer">
        <div class="container">
            <div class="row">
                <div class="col xl6 s12">
                    <h1>Disclaimer</h1>
                    <p>This is not intended for operational use. <br/>
                        You have to check actual wx and various limitations for operational use <br/>
                        TAF does not yet show values outside limit<br/>
                        Only shows wind in and out of limits (for now&trade;)
                    </p>
                </div>
            </div>
        </div>
        <div class="footer-copyright">
            <div class="container center-align">
                <span class="row">Contact me if you find any error /Mathias</span>
                <span class="row">&copy;2024</span>
                <span class="row">Version {{ version }}</span>
            </div>
        </div>
    </footer>
</template>

<script setup>
import config from '@/config.js';
import { ref } from 'vue';
const version = ref(config.version);
</script>

<style>

</style>