<script setup>
import 'leaflet/dist/leaflet.css';
import { ref,computed, provide, watch } from 'vue';
import config from '@/config.js';
import { socket } from '@/socket.js';
import router from './router';
//socket.off();
const initLocalStorage = localStorage.getItem(config.API_STORAGE);
const auth = ref(false)
try {
    auth.value = (JSON.parse(initLocalStorage) || false);
} catch(err){
    console.error('issues parsing local storage,',err);
    localStorage.setItem(config.API_STORAGE,JSON.stringify(false))
    auth.value = false;
}
provide('API_URL', config.API_URL);
provide('STORAGE_KEY', config.STORAGE_KEY);
provide('API_STORAGE', config.API_STORAGE);
provide('auth', auth);
provide('socket',socket);


watch(auth, async()=>{
    localStorage.setItem(config.API_STORAGE,JSON.stringify(auth.value));
    console.log(auth);
});
if(!auth.value){
    router.push('/login');
}

</script>

<template>
    <RouterView/>
</template>

<style>

</style>