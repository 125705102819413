<template>
    <nav>
        <div class="nav-wrapper blue-grey darken-1">
            <div class="brand-logo left">{{ title }}</div>
            <ul class="right">
                <li class="multiline clock">{{ clock.time }}<br/>{{ clock.date }}</li>
                <li>&nbsp;</li>
                <li class="multiline">Last update: {{ lastUpdate }} <br/> {{ connected }} to server</li>
            </ul>
        </div>
    </nav>
</template>

<script setup>
import { state } from '@/socket';
import { UTCdate,UTCtime } from '@/helpers';
import { computed, inject, onMounted, ref } from 'vue';

const clock = ref({
    time: '00:00:00z',
    date: '00/00/0000'
});

const connected = computed(()=>{
    if(state.connected){
        return "connected";
    } else {
        return "not connected"
    }
});
const lastUpdate = computed(()=>{
    if(state.lastPing != null){
        const time = new Date(state.lastPing);
        return UTCtime(time);
    } else {
        return 'never';
    }
});
const title = ref('Weather Display')


onMounted(()=>{
    setInterval(updateTime,100);
})
function updateTime(cd=new Date()) {
    clock.value.time = UTCtime(cd);
    clock.value.date = UTCdate(cd);
}



</script>

<style scoped>
    .multiline {
        margin-top: 1rem;
        line-height: 1;
        text-align: center;
    }
</style>