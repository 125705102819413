<template>
  <div class="container">
        <div class="card">
            <div class="card-content">
                <span class="card-title center-align">Login</span>
                <form @submit.prevent="submitPw">
                    <div class="input-field">
                        <input 
                            v-model="password" 
                            id="password" 
                            type="password" 
                            class="validate"
                            @keypress.enter="submitPw()"
                        >
                        <label for="password">Password</label>
                    </div>
                    <div class="card-action">
                        <a type="submit" @click="submitPw()">Sign in</a>
                    </div>
                </form>
            </div>
        </div>
  </div>
</template>

<script setup>
import router from '@/router';
import { restartSocket, socket, state as socketState } from '@/socket';
import { onMounted, ref, inject } from 'vue';
const API_URL = inject('API_URL');
const auth = inject('auth');
const API_STORAGE = inject('API_STORAGE');

const password = ref('');

function submitPw(){
    fetch(`${API_URL}/authenticate`,{
        method: "POST",
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({password: password.value})
    }).then(res => {
        if(res.ok){
            return res.json();
        } else {
            throw 'error doing JSON';
        }
    }).then(body=>{
        if(body.authenticated){
            auth.value = {
                token: body.token,
                expires: body.expires
            }
            socketState.authToken = body.token;
            restartSocket(auth.value.token);
            socket.connect();
            router.push('/');
        } else{
            console.log('invalid pw');
        }
    }).catch(err=>{
        console.error('auth issue:', err);
    })
}
</script>

<style>

</style>