function zeroPadding(num,digits){
    let zero = '';
    for(let i=0;i<digits;i++){
        zero+='0';
    }
    return (zero+num).slice(-digits);
}

function UTCtime(cd = new Date()){
    return zeroPadding(cd.getUTCHours(),2)+':'+
        zeroPadding(cd.getUTCMinutes(),2)+':'+
        zeroPadding(cd.getUTCSeconds(),2)+'z';
}
function UTCdate(cd = new Date()){
    return zeroPadding(cd.getUTCDate(),2)+'/'+
        zeroPadding(cd.getUTCMonth()+1,2)+'/'+
        zeroPadding(cd.getUTCFullYear(),4);
}

function UTC6dig(c = new Date()){
    const cd = new Date(Number(c));
    return zeroPadding(cd.getUTCDate(),2)+
        zeroPadding(cd.getUTCHours(),2)+
        zeroPadding(cd.getUTCMinutes(),2);
}

function tafValidToDate(dd,hh=0,mm=0){
    const cd = new Date();
    cd.setUTCDate(dd);
    cd.setUTCHours(hh);
    cd.setUTCMinutes(mm,0,0);
    return cd.getTime();
}

export {UTCdate,UTCtime, UTC6dig, tafValidToDate, zeroPadding}