import { version } from "../package.json";
let ret = {
    API_URL: `https://wxapi.raymannair.com/api`,
    FILE_URL: `https://wxapi.raymannair.com/s3`,
    SOCKET_URL: `https://wxapi.raymannair.com`,
    STORAGE_KEY: 'vue-wxairports2',
    API_STORAGE: 'vue-wxairports-key',
    version: version,
}
if(process.env.NODE_ENV==="development"){
    ret.API_URL=`http://localhost:3000/api`;
    ret.FILE_URL=  `http://localhost:3000/s3`;
    ret.SOCKET_URL = `http://localhost:3000/`;
}

export default ret;
